<template>
  <div>
    <vca-row>
      <p class="col_1">{{ $t("deposit.info.taking.type") }}</p>
      <p class="col_2">{{ $t("deposit.info.taking.amount") }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.account") }}</p>
    </vca-row>
    <br />
    <h3>{{ $t("deposit.info.taking.headline_sum") }}</h3>
    <vca-row v-if="showAmountType('concert')">
      <p class="col_1">{{ $t("deposit.info.taking.concert.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountType("concert")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.concert.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountType('festival')">
      <p class="col_1">{{ $t("deposit.info.taking.festival.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountType("festival")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.festival.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountType('stadium')">
      <p class="col_1">{{ $t("deposit.info.taking.stadium.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountType("stadium")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.stadium.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountType('network')">
      <p class="col_1">{{ $t("deposit.info.taking.network.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountType("network")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.network.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountOtherType()">
      <p class="col_1">{{ $t("deposit.info.taking.other.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountOtherType()) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.other.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountNoType()">
      <p class="col_1">{{ $t("deposit.info.taking.no_event.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountNoType()) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.no_event.account") }}</p>
    </vca-row>
    <hr style="width: 70%" align="left" />
    <vca-row>
      <p class="col_1">{{ $t("deposit.info.taking.sum_donation") }}</p>
      <p class="col_2">{{ formatMoney(getDonation()) }}</p>
      <p class="col_3"></p>
    </vca-row>
    <br /><br />
    <h3>{{ $t("deposit.info.taking.headline_eco") }}</h3>
    <vca-row v-if="showAmountValue('merch')">
      <p class="col_1">{{ $t("deposit.info.taking.merch.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountValue("merch")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.merch.account") }}</p>
    </vca-row>
    <vca-row v-if="showAmountValue('other_ec')">
      <p class="col_1">{{ $t("deposit.info.taking.other_ec.type") }}</p>
      <p class="col_2">{{ formatMoney(getAmountValue("other_ec")) }}</p>
      <p class="col_3">{{ $t("deposit.info.taking.other_ec.account") }}</p>
    </vca-row>
    <hr style="width: 70%" align="left" />
    <vca-row>
      <p class="col_1">{{ $t("deposit.info.taking.sum_eco") }}</p>
      <p class="col_2">{{ formatMoney(getEco()) }}</p>
      <p class="col_3"></p>
    </vca-row>
  </div>
</template>
<script>
export default {
  name: "DepositTakingInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          deposit_units: [],
        };
      },
    },
  },
  methods: {
    showAmountType(value) {
      return !(this.getAmountType(value).amount === 0);
    },
    showAmountOtherType() {
      return !(this.getAmountOtherType().amount === 0);
    },
    showAmountNoType() {
      return !(this.getAmountNoType().amount === 0);
    },
    showAmountValue(value) {
      return !(this.getAmountValue(value).amount === 0);
    },
    getDonation() {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        e.taking.sources.forEach((f) => {
          if (f.norms === "donation") {
            amount += f.money.amount;
          }
        });
      });
      return { amount: amount, currency: "EUR" };
    },
    getEco() {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        e.taking.sources.forEach((f) => {
          if (f.norms === "economic") {
            amount += f.money.amount;
          }
        });
      });
      return { amount: amount, currency: "EUR" };
    },
    getAmountType(value) {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        if (e.taking.event.type_of_event === value) {
          e.taking.sources.forEach((f) => {
            if (f.norms == "donation") {
              amount += f.money.amount;
            }
          });
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getAmountNoType() {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        if (e.taking.event.id === "") {
          e.taking.sources.forEach((f) => {
            if (f.norms == "donation") {
              amount += f.money.amount;
            }
          });
        }
      });
      return { amount: amount, currency: "EUR" };
    },
    getAmountOtherType() {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        if (
          !(
            e.taking.event.id === "" ||
            e.taking.event.type_of_event == "concert" ||
            e.taking.event.type_of_event == "festival" ||
            e.taking.event.type_of_event == "network" ||
            e.taking.event.type_of_event == "stadium"
          )
        ) {
          e.taking.sources.forEach((f) => {
            amount += f.money.amount;
          });
        }
      });
      return { amount: amount, currency: "EUR" };
    },

    getAmountValue(value) {
      let amount = 0;
      this.value.deposit_units.forEach((e) => {
        e.taking.sources.forEach((f) => {
          if (f.value === value) {
            amount += f.money.amount;
          }
        });
      });
      return { amount: amount, currency: "EUR" };
    },
  },
};
</script>
<style lang="scss">
.col_1 {
  flex-basis: 50%;
}
.col_2 {
  flex-basis: 25%;
}
.col_3 {
  flex-basis: 25%;
}
</style>
